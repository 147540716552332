import { navigate } from "gatsby-link"
import React from "react"

export default function RacordareIndexPage() {
  React.useEffect(() => {
    navigate("1")
  }, [])

  return null
}
